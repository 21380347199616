.portfolio{
    overflow: hidden;
    --clip-large:110%;
    --clip-small:-10%;
    background-color: transparent;
    position: relative;
    z-index: 100;
    margin-bottom: 30px;
}

.wrapper-outter{
    overflow: hidden;
    display: none;
}
.wrapper-outter .wrap{
    margin-top: -12.5vw;
    margin-bottom: -12.5vw;
    overflow: hidden;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    /* background: white; */
	background-size: 400% 400%;
	animation: gradient 5s ease infinite;
}

.wrapper-outter .line{
    display: flex;
    filter: drop-shadow(6px 6px 6px rgba(0, 0, 0, 1));
}

.wrapper-outter .line:not(:first-child){
    margin-top: -4vw;
}

.wrapper-outter .line:nth-child(even){
    transform: translateX(-12vw);
}

.wrapper-outter .item{
    position: relative;
    background-color: #aaa;
    -webkit-clip-path: polygon(50% 0%, 97% 25%, 97% 75%, 50% 100%, 3% 75%, 3% 25%);
    clip-path: polygon(50% 0%, 97% 25%, 97% 75%, 50% 100%, 3% 75%, 3% 25%);
    flex-shrink: 0;
    color: white;
    text-align: center;
    line-height: 24vw;
    cursor: pointer;
}

.wrapper-outter .item:hover{
    z-index: 100;
    transform: scale(1.2);
    background: blanchedalmond;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media screen and (min-width: 1199.98px) {
    .portfolio{
        padding:5vh 4vw 0 0;
    }
    
    .wrapper-outter{
        display: block;
    }
    .item{
        width: 24vw;
        height: 24vw;
    }

    .portfolio .mobile-wrapper{
        display: none;
    }
}

@media screen and (max-width: 1199.98px) and (min-width: 767.98px) {
    .portfolio{
        padding:3vh 0 0 0;
    }

    .wrapper-outter .item{
        width: 24vw;
        height: 24vw;
    }
}

@media screen and (max-width: 767.98px) {
    .portfolio{
        padding:0;
    }

    .wrapper-outter  .item{
        width: 25vw;
        height: 25vw;
    }
}

.portfolio .mobile-wrapper{
    /* --text-color: var(--text-color); */
    --bg-color: #333;
    width: 100%;
    padding: 15px;
    background: var(--bg-color);
    color: var(--text-color);
}

.portfolio .mobile-wrapper .line{
    display: flex;
    gap: 4%;
    flex-direction: row;
    position: relative;
    margin: 20px 0;
}

.portfolio .mobile-wrapper .line .item{
    position: relative;
    flex: 1 0 0;
    padding: 30px 0;
}

.portfolio .mobile-wrapper .line .item::before{
    content: "";
    height: 2px;
    background: var(--text-color);
    width: 100%;
    display: block;
    top: 0;
    position: absolute;
}

.portfolio .mobile-wrapper .line .item .title{
    font-size: 18px;
    font-weight: 600;
}

.portfolio .mobile-wrapper .line .item .logo{
    height: 45px;
    margin-top: 30px;
}

.portfolio .mobile-wrapper .line .item img{
    height: 45px;
    width: 45px;
    /* filter: grayscale(100%);  */
    /* background-blend-mode: difference; */
}

@media screen and (max-width: 767.98px) {
    .portfolio .mobile-wrapper .line .item .title{
        font-size: 16px;
        font-weight: 600;
        height: 3em; 
    }
}
