#contactUs{
    background: black;
    color: blanchedalmond;
}
#contactUs .container{
    padding: 20px 0;
}
#contactUs .card{
    background: #333;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    padding: 20px;
}

#contactUs .card .icon{
    width: 50px;
    height: 50px;
    font-size: 24px;
    border-radius: 50%;
    background: #555;
    display: flex;
    align-items: center;
    justify-content: center;
}
#contactUs .card .text-wrapper{
    margin-left: 20px;
}

#contactUs .card .text-wrapper h4{
    font-weight: 700;
}