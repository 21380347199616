:root{
    /* --theme-color-rgba: 90, 164, 174; */
    --theme-color-rgba:255,255,255;
    --theme-r:90;
    --theme-g:164;
    --theme-b: 174;
    --theme-color: #333;
    --second-color: #444;
    --red-color: #cd383d;
    --xs: 768px;
    --sm: 768px;
    --md: 992px;
    --lg: 1200px;
    --bs-body-font-family:Verdana, Geneva, Tahoma, sans-serif!important;
    font-family:"Segoe UI";
    --text-color:blanchedalmond;
}

.App{
    position: relative;
    background: #333;
}