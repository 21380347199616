.LinkButton{
    border:4px solid;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    display: block;
    text-decoration: none;
}

.LinkButton.dark{
    border-color:white;
    color: white;
    /* background-color: var(--second-color); */
}

.LinkButton.dark:hover{
    border-color: var(--second-color);
    color: var(--second-color);
    background-color: white
}

.LinkButton.light{
    border-color: var(--second-color);
    color: var(--second-color);
    background-color: white
}

.LinkButton.light:hover{
    border-color:white;
    color: white;
    background-color: var(--second-color);
}

.LinkButton.trans-white{
    background-color: transparent;
    color: white;
    border-color:white;
}

@media screen and (max-width: 767.98px) {
    .LinkButton{
        width:170px;
        height: 45px;
        line-height: 37px;
    }
}

@media screen and (min-width: 767.98px) and (max-width: 1199.98px) {
    .LinkButton{
        width:200px;
        height: 60px;
        line-height: 52px;
    }
}

@media screen and (min-width: 1199.98px) {
    .LinkButton{
        width:280px;
        height: 60px;
        line-height: 52px;
        font-size: 24px;
    }
}