.outer-ring{
    position: relative;
    height: 300px;
    width: 300px;
    background-image: linear-gradient(135deg,#feed07 10%, #fe6a50 15%,
    #ed00aa 20%,
    #2fe3fe 50%,
    #8900ff 100%);
    border-radius: 50%;
    animation: rotate 3.5s infinite;
    
}
.inner-ring{
    position:absolute;
    left: 10px;
    top: 10px;
    height: 280px;
    width: 280px;
    background-color: #000;
    backdrop-filter: blur(10px);
    border-radius: 50%;
    color: var(--text-color);
    text-align: center;
    line-height: 280px;
    animation: anti-rotate 3.5s infinite;
}

@keyframes rotate {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg);}
}

@keyframes anti-rotate {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(-360deg);}
}