.titleSection{
    width: 100%;
    height: 250px;
    text-align: center;
    line-height: 250px;
    color: var(--text-color);
    font-size: 36px;
    font-weight: bold;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media screen and (max-width: 767.98px) {
    .titleSection{
        height: 150px;
        line-height: 150px;
        font-size: 25px;
    }
}

.titleSection span{
    /* animation: spread 1.8s ease-in-out infinite alternate; */
}

@keyframes spread {
    to{
        color: var(--red-color);
        text-shadow: 20px 0 70px #2fe3fe;
    }
}