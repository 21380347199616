#procedure{
    background: #222;
    color: blanchedalmond;
    
}

@media screen and (min-width: 1199.98px) {
    .blank{
        height: 150px;
        background: black;
    }
}
#procedure .steps{
    padding: 30px 0;
}

#procedure .steps .icon-wrapper{
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #444;
    border-radius: 10px;
    box-shadow: -10px -10px 10px rgb(255,225,205, 1),
                10px 10px 10px rgba(0, 0, 0, 0.5);
}

#procedure .steps .icon{
    font-size: 40px;
}
/* 
#procedure .steps .icon-wrapper:hover{
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2),
    0 0 0 rgba(0, 0, 0, 0.8),
    inset -10px -10px 10px rgb(255,225,205, 1),
    inset 10px 10px 10px rgba(0, 0, 0, 0.2);;
} */

#procedure .steps h3{
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
}

#procedure .steps .step-detail{
    background-color:var(--text-color);
    padding: 20px;
    color: gray;
}

#procedure .steps .row{
    margin-bottom: 20px;
}

#procedure .steps .row h3{
    font-weight: 800;
}

#procedure .steps .row h2{
    font-size: 20px;
    font-weight: 800;
}

@media screen and (min-width: 767.98px) and (max-width: 1199.98px) {
    #procedure .steps .row {
        text-align: center;
    }

    #procedure .steps .icon-wrapper{
        margin:0 auto;
    }
}

@media screen and (max-width: 767.98px) {
    #procedure .container{
        margin: 0 10px;
    }

    #procedure .steps .row {
        text-align: center;
    }

    #procedure .steps .icon-wrapper{
        margin:0 auto;
    }
}

@media screen and (min-width: 1199.98px) {

    #procedure .steps .row {
        text-align: left;
    }

    #procedure .steps .icon-wrapper{
        margin: 0 auto;
    }
}