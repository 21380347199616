#home .slogan-wrapper .wrapper{
    display: flex;
    align-items: center;
    backdrop-filter: blur(5px);
    padding-right: 50px;
}
#home .slogan-wrapper{
    position: relative;
    background: transparent;
    width: 100%;
    height: 100vh ;
    z-index: 100;
    /* mix-blend-mode: difference; */
}

#home .slogan{
    color:blanchedalmond;
    font-size: 80px;
    font-weight: 800;
    /* mix-blend-mode: difference; */
    filter: url(#noise);
}


#home h2.title {
    color: blanchedalmond;
    font-size: 40px;
    position: relative;
    z-index: 100;
    font-weight: 700;
    margin-left: 5vw;
}

@media screen and (min-width: 1199.98px) {
    #home .slogan-wrapper{
        display: flex;
        align-items: center;
        padding-left: 10%;
    }

    #home .slogan{
        margin-left: 40px;
    }
}

@media screen and (max-width: 1199.98px) and (min-width: 767.98px) {
    #home .slogan-wrapper{
        display: flex;
        align-items: center;
        padding-left: 10%;
        height: calc(100vh - 90px);
    }

    #home .slogan{
        margin-left: 20px;
    }

    #home .slogan-wrapper .wrapper{
        padding-right: 0;
    }
}

@media screen and (max-width: 767.98px) {
    #home .slogan-wrapper{
        margin: 0 auto;
        height: calc(100vh - 90px);
    }
    
    #home .outer-ring{
        margin: 30px auto 20px auto;
    }

    #home .slogan{
        margin-top: 10px;
        text-align: center;
    }

    #home .slogan-wrapper .wrapper{
        display: block;
        padding-right: 0px;
        backdrop-filter: blur(1px);
    }
}