.notFound img{
    margin:0 auto;
    display: block;
}

.notFound{
    background-color: #f5f5f5;
}

.notFound .text{
    margin-top: 15px;
    text-align: center;
}

.notFound a{
    margin:0 auto;
}

@media screen and (max-width: 767.98px) {
    .notFound{
       padding: 50px 0;
    }
}

@media screen and (min-width: 767.98px) {
    .notFound{
       padding: 200px 0;
    }
}