.footer{
    position: relative;
    z-index: 10;
    background-color: var(--theme-color);
    color: var(--text-color);
    width: 100%;
    padding:30px 0;
}

@media screen and (max-width: 1199.98px) {
    .footer .info-section{
        text-align: center;
    }
}

.footer .info{
    padding: 10px 0;
}

.footer .title{
    font-size: 18px;
    font-weight: 600;
}

.footer ul{
    margin-top: 20px;
    padding-left:1px;
}

.footer li{
    list-style-type: none;
    font-size: 16px;
    padding:5px 0;
}

.footer .menu a{
    display: block;
    color: var(--text-color);
    text-decoration: none;
    width: 100%;
}

.footer .menu a:hover{
    text-decoration: underline;
}
