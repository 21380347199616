#menu .header{
    display: flex;
    margin-left: 50px;
    align-items: center;
    height: 100%;
}

#menu .title {
    --opac:calc(var(--factor,1) - 1);
    height: 100px;
    width: calc(var(--factor,1) * 100px);
    font-size: calc(var(--factor,1) * 18px);
    scale: var(--factor,1) ;
    background-color: rgba(var(--theme-color-rgba),calc(var(--opac)*5/var(--factor)));
    border-radius: 10px;
    text-align: center;
    line-height: 100px;
    cursor: pointer;
    font-weight: 600;
    display: block;
    margin-bottom: calc((var(--factor) - 1) * 60px);
    color: var(--text-color);
}

#menu .title:hover{
    color: var(--text-color);
    /* background-color: rgba(255, 255, 255, 0.1); */
}

#menu .space {
    width: 45px;
}