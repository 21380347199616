#services{
    background: black;
    color: blanchedalmond;
}

#services .container{
    padding: 30px 0px;
}

#services .wrapper{
    display: flex;
    flex-direction: row;
}

#services .wrapper .element{
    flex: 1 0 0;
}

#services .wrapper:nth-child(even){
    flex-direction: row-reverse;
}

#services .wrapper:nth-child(1){
    background-color: #eaeaea;
    color: rgb(64, 76, 99)
}
#services .wrapper:nth-child(1) .eng:after{
    background-color: rgb(64, 76, 99);
}
#services .wrapper:nth-child(2){
    background-color: rgb(77, 132, 241);
    color: white;
}
#services .wrapper:nth-child(2) .eng:after{
    background-color: white;
}
#services .wrapper:nth-child(3){
    background-color: #ffd954;
    color: black;
}
#services .wrapper:nth-child(3) .eng:after{
    background-color: black;
}
#services .wrapper:nth-child(4){
    background-color: #eb4936;
    color: #dcdac6;
}
#services .wrapper:nth-child(4) .eng:after{
    background-color: #dcdac6;
}
#services .wrapper .text-wrapper{
    padding: 75px 0 20px 48px;
}
#services .wrapper .eng::after{
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 40%;
    height: 2px;
}
#services .wrapper .eng{
    position: relative;
    font-size: 84px;
}

#services .wrapper .cn{
    font-size: 48px;
}

#services .wrapper .desc{
    font-size: 16px;
}
#services .wrapper img{
    display: block;
    margin: 30px auto;
    width:calc(100% - 40px); 
    transition: transform .5s ease;
}
#services .wrapper:hover img{
    transform: scale(1.2);
}
@media screen and (min-width: 767.98px) and (max-width: 1199.98px) {
    #services .wrapper .text-wrapper{
        padding:25px 15px 20px 15px;
    }
    #services .wrapper .eng{
        font-size: 24px;
    }
    
    #services .wrapper .cn{
        font-size: 20px;
    }
}

@media screen and (max-width: 767.98px) {
    #services{
        padding: 0 15px;
    }

    #services .wrapper .image{
        display: none;
    }

    #services .wrapper .text-wrapper{
        padding:25px 15px 20px 15px;

    }
    #services .wrapper .eng{
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    
    #services .wrapper .cn{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 14px;
    }
}

@media screen and (min-width: 1199.98px) {
    #services .wrapper{
        height: 500px;
    }

    #services .wrapper .image{
        display: flex;
        align-items: center;
    }
}
/* 
#services .card{
    background: #333;
    padding: 80px 20px;
    margin-top: 1.5rem;
    height: calc(100% - 1.5rem); 
}

#services .card h4{
    text-align: center;
}

#services .card .icon{
    margin: 0 auto;
    width: 64px;
    height: 64px;
    font-size: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #666;
    border-radius: 10px;
}

#services .card h4{
    margin: 15px 0;
} */