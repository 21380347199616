#aboutUs{
    background: #222;
}
#aboutUs .text{
    color: blanchedalmond;
    margin: 0 auto;
    padding: 50px;
    font-size: 30px;
    font-weight: 600;
    line-height: 80px;
}

@media screen and (min-width: 1199.98px) {
    .blank{
        height: 150px;
        background: black;
    }
    #aboutUs .text{
        max-height: 500px;
    }
}

@media screen and (max-width: 767.98px) {
    #aboutUs .text{
        padding: 30px;
        font-size: 20px;
        font-weight: 600;
        line-height: 40px;
    }
}