#menu{
    --factor:1;
    z-index: 1000;
    position: relative;
}

#menu .dd-menu .title-link{
    background-color: #E8EFF7;
}

#menu .dd-menu .title-link:hover{
    background-color: white;
}

#menu .dd-menu a:hover{
    border-left: 3px solid #CD383D;
}

.login{
    font-size: 14px;
}

.menu-bg{
    z-index: 3;
    width: 100%;
    height: 150px;
    background-color: var(--theme-color);
}

.Menu{
    top: 0;
    position: fixed;
    width: 100%;
    height: 150px;
    /* background-color: var(--theme-color); */
    /* background-image: radial-gradient(transparent 1px, var(--theme-color) 1px);
    background-size: 4px 4px;
    backdrop-filter: saturate(50%) blur(5px); */
    backdrop-filter:  blur(15px);
    background: linear-gradient(100deg,rgba(var(--theme-color-rgba),0.8),rgba(var(--theme-color-rgba),0.1));
    box-shadow: 0 10px 26px 0 rgba(0,0, 0, 0.5);
}

#menu .full-screen-menu{
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
    height: 100%;
}

#menu .arrow{
    margin-left: 10px;
    transition: transform 0.5s ease-in-out;
}

#menu .title, .link{
    color: var(--text-color);
    text-decoration: none;
    font-size: 18px;
}

.Menu .dd {
    position: relative;
}

.Menu .dd-menu{
    visibility: hidden;
    position: absolute;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    opacity: 0;
    transform: translateY(-30px);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.Menu .dd-menu a{
    display: inline-block;
    padding: 15px;
    color: black;
    text-decoration: none;
    width: 100%;

}

.Menu .dd-menu a:hover{
    background-color: rgba(255, 255, 255, 1);
}

.dd >.title:hover + .dd-menu , .dd-menu:hover{
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

#menu .dd:hover .arrow {
    transform: rotate(-180deg);
}

@media screen and (min-width: 767.98px) and (max-width: 1199.98px) {
    #menu{
        padding:5px 0;
        background-color: var(--theme-color);
    }

    #menu .navbar-brand{
        margin-left: 30px;
    }
     
    #menu img{
        height: 60px; 
        width: 60px;
    }
}

@media screen and (max-width: 767.98px) {
    #menu .navbar-brand{
        margin-left: 15px;
    }
}

.sidebar-wrapper{
    background-color: rgba(190, 190, 190, 0.7);
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 101;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    display: block;
    transform: translate(-105%);
}
.sidebar-wrapper.toggle{
    transform: translate(0%);
}

.sidebar-wrapper .sidebar{
    transform: translate(-105%);
    transition: all 1s;
}

.sidebar-wrapper.toggle .sidebar{
    transform: translate(0%);
}

.sidebar-wrapper.toggle .sidebar .section{
    transform: translate(-105%);
    animation: move 1s forwards;
}

@keyframes move {
    to{
        transform: translate(0);
    }
}

.sidebar-wrapper .row{
    height: 100%;
}

#sidebar{
    background-color: var(--theme-color);
    color: var(--text-color);

}

#sidebar .close{
    display: block;
    float: right;
    font-size: 30px;
    font-weight: 800;
    margin: 10px;
    padding: 10px;
    border: none;
    color: var(--text-color);
    background-color: var(--theme-color);
}

#sidebar .title{
    font-weight: 800;
}

#sidebar .link{
    display: block;
    font-size: 20px;
    padding:15px 10px;
}

#sidebar .sub{
    padding-left: 40px;
}

@media screen and (max-width: 767.98px) {
    #sidebar .logo img{
        margin:10px 3px;
    }

    #sidebar img{
        width: 50px;
    }

    #sidebar .close{
        font-size: 25px;
        margin: 5px;
    }

    #sidebar .link{
        padding:12px 10px;
        font-size: 16px;
    }

    #sidebar .sub{
        padding-left: 30px;
    }
}

@media screen and (min-width: 767.98px) {
    #sidebar .logo img{
        margin:15px 0 0 18px;
    }
}